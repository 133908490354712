import React, { useContext } from "react"
import { motion } from "framer-motion"
import Layout from "../components/Layout"
import Header from "../components/Header"
import { FancyLead, StyledArticle } from "../components/components.css"
import FancyTitle from '../components/FancyTitle'

import MenuButton from "../components/Menu/MenuButton"

import { MenuContext } from "../context/MenuContext"
import MainMenu from "../components/Menu/MainMenu"
import SecondaryMenu from "../components/Menu/SecondaryMenu"


function About() {

  return (
    <Layout>
      <Header>
        <FancyTitle title="About" />
        <FancyLead>Here are some things you should know.</FancyLead>
      </Header>
      <StyledArticle>
        <ul class="content-list">
          <li>I'm happily married to my wonderful wife Sarah.</li>
          <li>I'm from a small city called Hamilton and I love this place.</li>
          <li>I love to drink beer, specifically IPAs.</li>
          <li>I'm a huge hockey fan, specifically the Vancouver Canucks. (Take a look at the flying skate jerseys and then this site).</li>
          <li>I think the Jamstack and Gatsby are almost ready to take the over the world.</li>
          <li>I'm a PC Dev turned Mac Dev.</li>
          <li>I'm also a graduate from MacDiv</li>
        </ul>
      </StyledArticle>
    </Layout>
  )
}

export default About
